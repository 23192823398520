export class GetCertificateOutput {
  public readonly subject!: string;
  public readonly issuerName!: string;
  public readonly thumbprint!: string;
  public readonly cpf!: string;
  public readonly expiresAt!: Date;

  constructor(data: Partial<GetCertificateOutput>) {
    Object.assign(this, data);
  }
}
